<template>
  <div>
    <div class="shares flex" :style="{ left: fc + 'px', top: top + 'px' }">
      <div class="left">
        <p class="grets">
          <img
            src="@/static/job/wechat.png"
            alt
            class="img inline_block"
          />微信扫码
        </p>
        <div id="qrcode" ref="qrcode"></div>
      </div>
      <ul class="right">
        <li>
          <a href="javascript:void(0);" @click="shareToMicroblog">
            <img
              src="@/static/job/sina.png"
              alt
              class="img inline_block"
            />新浪微博
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" @click="shareToQQ">
            <img src="@/static/job/qq.png" alt class="img inline_block" />
            QQ好友
          </a>
        </li>
        <!--          <li>-->
        <!--            <router-link to="/" target="_blank">-->
        <!--              <img src="@/static/job/circle_of_friends.png" alt class="img inline_block"/>朋友圈-->
        <!--            </router-link>-->
        <!--          </li>-->
      </ul>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {};
  },
  props: ["url", "title", "desc", "image", "fc", "top"],
  methods: {
    // 生成二维码
    qrCode() {
      console.log(this.url);
      this.$nextTick(function () {
        document.getElementById("qrcode").innerHTML = "";
        let qrcode = new QRCode("qrcode", {
          width: 79 * 5,
          height: 79 * 5,
          text: this.url, // 二维码内容 也可以放url
          colorDark: "#000",
          colorLight: "#fff",
          correctLevel: QRCode.CorrectLevel.H,
        });
        qrcode.clear(); // clear the code.
        qrcode.makeCode(this.url); // make another code.
      });
    },
    //分享到QQ好友(PC端可用)
    shareToQQ() {
      //此处分享链接内无法携带图片
      window.open(
        "https://connect.qq.com/widget/shareqq/index.html?url=" +
          encodeURIComponent(this.url) +
          "&title=兰州市残疾人就业创业网络服务平台为残疾人及企业竭诚服务！" +
          this.title +
          "&desc=兰州市残疾人就业创业网络服务平台是中国具有广泛影响力的人力资源服务供应商。它运用了网络媒体及先进的移动端信息技术，加上经验丰富的专业顾问队伍，提供包括招聘猎头、培训测评和人事外包在内的全方位专业人力资源服务，现在全国25个城市设有服务机构每年为数十万家企业成功招募所需人才，帮助企业高效准确地锁定目标，用短的时间、经济的成本找到合适的人才。同时，兰州市残疾人就业创业网络服务平台拥有过亿的个人用户，每年成功为全国求职者提供适合的工作和职业发展机会。"
      );
    },
    //分享到微博(可用)
    shareToMicroblog() {
      //自定义内容
      //跳转地址
      window.open(
        "https://service.weibo.com/share/share.php?url=" +
          encodeURIComponent(this.url) +
          "&title=兰州市残疾人就业创业网络服务平台为残疾人及企业竭诚服务！" +
          this.title +
          "&pic=" +
          this.image +
          "&searchPic=true"
      );
    },
  },
  mounted() {
    this.qrCode();
  },
};
</script>

<style lang="less" scoped>
.share {
  .grets {
    color: #333 !important;
  }
  float: right;
  color: gray;
  transition: all 0.3s;

  i {
    transition: all 0.3s;
    font-size: 12px;
  }

  .shares {
    display: none;
    position: absolute;
    top: 20px;
    right: 0;
    width: 270px;
    height: 140px;
    background: rgba(255, 255, 255, 1);
    border-radius: 2px;
    background: white;
    padding: 15px 0;
    box-shadow: 0px 2px 16px 0px rgba(13, 4, 9, 0.06);
    z-index: 100;

    .left {
      flex: 1;
      border-right: 1px solid #e1e1e1;
      text-align: center;
      margin-right: 10px;
      p {
        img {
          width: 20px;
          margin-right: 5px;
        }

        font-size: 12px;
        color: #33333333;
      }

      .or {
        width: 79px;
        height: 79px;
      }
    }

    .right {
      flex: 1;

      li {
        margin-left: 10px;
        margin-bottom: 25px;

        img {
          width: 20px;
          margin-right: 5px;
        }

        font-size: 12px;
      }
    }
  }
}

.share:hover .shares {
  display: flex;
}

.share:hover i {
  transform: rotate(180deg);
}
#qrcode {
  display: inline-block;
  margin-top: 10px;
  width: 79px;
  height: 79px;
  /deep/canvas {
    width: 300px;
    height: 300px;
  }
  /deep/ img {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
