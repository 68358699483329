<template>
  <div class="detail" v-loading="loading" element-loading-text="拼命加载中">
    <div class="content flex">
      <div class="content_left">
        <h1 class="title_h1">
          {{ detail.title }}
        </h1>
        <p class="times">
          <span
          ><img
              src="~/static/news/shijian.png"
              alt="时间"
              class="img middle marR5"
          />{{ detail.createTime }}</span
          >
          <span
          ><img
              src="~/static/news/liulan.png"
              alt="阅读量"
              class="img middle marR5"
          />{{ detail.reading }}</span
          >
          <span
              v-if="detail.author">
            <i title="来源" class="el-icon-s-home" style="fontSize:19px;"></i>
            {{ detail.author }}</span
          >
        </p>
        <div class="text" v-html="detail.clob"></div>
      </div>
      <div class="content_right">
        <div class="share relative">
          <span>分享到 <i class="el-icon-arrow-down"></i></span>
          <Share
              :url="shareUrl"
              :title="detail.title"
              top="60"
              v-if="shareUrl"
          ></Share>
        </div>
        <div class="box" v-show="active != 0">
          <div class="title">
            <h2>新闻动态</h2>
            <i
                class="icon icon el-icon-d-arrow-right"
                @click="goRouter('/news/new_list')"
                title="更多"
            ></i>
          </div>
          <ul>
            <li class="first flex">
              <div class="info" v-for="(n, i) in list.slice(0, 2)" :key="i">
                <el-image
                    :src="n.thumbnail"
                    :alt="n.title"
                    :title="n.title"
                    class="banner"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                  <div slot="error" class="image-slot">
                    <img src="~/static/public/defaults.png" class="banner" alt="">
                  </div>
                </el-image>
                <a
                    href="javascript:void(0);"
                    :alt="n.title"
                    :title="n.title"
                    class="line_clamp1"
                    @click="goRouter('/news/newDetail', n.id)"
                >{{ n.title }}</a
                >
              </div>
            </li>
            <li
                v-for="(n, i) in list.slice(2, 8)"
                :key="i"
                class="li line_clamp1"
            >
              <a
                  href="javascript:void(0);"
                  :title="n.title"
                  @click="goRouter('/news/newDetail', n.id)"
              >
                <span v-show="n.author">「{{ n.author }}」</span>{{ n.title }}
              </a>
            </li>
          </ul>
        </div>
        <div class="box" v-show="active != 1">
          <div class="title">
            <h2>政策要闻</h2>
            <i
                class="icon icon el-icon-d-arrow-right"
                title="更多"
                @click="goRouter('/news/msg_list')"
            ></i>
          </div>
          <ul>
            <li v-for="(n, i) in list1" :key="i" class="li line_clamp1">
              <a
                  href="javascript:void(0);"
                  :title="n.title"
                  @click="goRouter('/news/newDetail', n.id)"
              >
                <span v-show="n.author">「{{ n.author }}」</span>{{ n.title }}
              </a>
            </li>
          </ul>
        </div>
        <div class="box" v-show="active != 2">
          <div class="title">
            <h2>通知公告</h2>
            <i
                class="icon icon el-icon-d-arrow-right"
                title="更多"
                @click="goRouter('/news/msg_list')"
            ></i>
          </div>
          <ul>
            <li v-for="(n, i) in list2" :key="i" class="li line_clamp1">
              <a
                  href="javascript:void(0);"
                  :title="n.title"
                  @click="goRouter('/news/newDetail', n.id)"
              >
                <span v-show="n.author">「{{ n.author }}」</span>{{ n.title }}
              </a>
            </li>
          </ul>
        </div>
        <div class="box" v-show="active != 3">
          <div class="title">
            <h2>残疾人风采</h2>
            <i
                class="icon icon el-icon-d-arrow-right"
                title="更多"
                @click="goRouter('people_list')"
            ></i>
          </div>
          <ul>
            <li v-for="(n, i) in list3" :key="i" class="li line_clamp1">
              <a
                  href="javascript:void(0);"
                  :title="n.title"
                  @click="goRouter('/news/newDetail', n.id)"
              >
                <span v-show="n.author">「{{ n.author }}」</span>{{ n.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Share from "@/components/public/Collect";

export default {
  name: "newDetail",
  components: {
    Share,
  },
  metaInfo() {
    return {
      meta: this.metaData,
    };
  },
  data() {
    return {
      active: "",
      detail: [],
      metaData: [],
      id: "",
      loading: false,
      list: [],
      list1: [],
      list2: [],
      list3: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        leve: '',
        city: '',
      },
      shareUrl: "",
      location: "",
    };
  },
  methods: {
    info() {
      this.loading = true;
      this.$api
          .getDetailApi({id: this.id})
          .then((res) => {
            this.loading = false;
            if (res.data.success) {
              this.detail = res.data.data;
              this.location = localStorage.getItem('locationSite') || '';
              if (this.location) {
                this.page.leve = '本省';
                this.page.city = localStorage.getItem('locationText') || '';
                if (this.location == '湖南省') {
                  this.page.city = '长沙市';
                } else if (this.location == '河南省') {
                  this.page.city = '长沙市';
                } else if (this.location == "陕西省") {
                  this.page.city = '西安市';
                } else if (this.location == "甘肃省") {
                  this.page.city = '兰州市';
                }
              }
              document.title =
                  this.detail.title + "-" + location == '甘肃省' ? '兰州市' : location + "残疾人就业创业网络服务平台";
              this.metaData.push(
                  {
                    name: "keywords",
                    content: this.detail.seoKey + ",残疾人找工作,残保金,残疾人联合会,残疾人就业保障金",
                  },
                  {
                    name: "description",
                    content: this.detail.seoDes + "," + location + "残疾人就业创业网络服务平台",
                  }
              );
              this.shareUrl = window.location.href;
              this.news();
              this.policy();
              this.message();
              this.styles();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    //新闻
    news() {
      this.page.consultationClassName = "兰州新闻动态";
      this.$api
          .newsListApi(this.page)
          .then((res) => {
            this.loading = false;
            if (res.data.success) {
              this.list = res.data.data.records;
            }
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    //政策
    policy() {
      let page = {};
      page.leve = this.page.leve;
      page.city = this.page.city;
      page.pageSize = 6;
      page.consultationClassName = "兰州政策法规";
      this.$api
          .newsListApi(page)
          .then((res) => {
            this.loading = false;
            if (res.data.success) {
              this.list1 = res.data.data.records;
            }
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    //通知
    message() {
      let page = {};
      page.leve = this.page.leve;
      page.city = this.page.city;
      page.pageSize = 6;
      page.consultationClassName = "兰州通知公告";
      this.$api
          .newsListApi(page)
          .then((res) => {
            this.loading = false;
            if (res.data.success) {
              this.list2 = res.data.data.records;
            }
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    //残疾人风采
    styles() {
      let page = {};
      page.leve = this.page.leve;
      page.city = this.page.city;
      page.pageSize = 6;
      page.consultationClassName = "兰州人物故事";
      this.$api
          .newsListApi(page)
          .then((res) => {
            this.loading = false;
            if (res.data.success) {
              this.list3 = res.data.data.records;
            }
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.active = this.$route.query.active;
      this.info();
    }
    //判断是否移动端
    this.$mobile(
        "https://lzmz.cdpee.org.cn/#/pages/information/detail?id=" +
        this.$route.query.id
    );
  },
  mounted() {
    this.$emit("goRouter", "/news/");
    this.$emit("goRouter", "/hunan/new/");
  },
};
</script>

<style scoped lang="less">
@import "../../assets/css/news.less";

.title_h1 {
  line-height: 1.2em;
}

.times {
  display: flex;

  span {
    flex: 1
  }

  .icon {
    font-size: 18px;
  }

  .el-icon-s-home {
    color: rgb(199, 199, 199);
  }
}
/deep/ .text{
  p,span,strong{
    font-size: 19px;
    line-height: 2em !important;
  }
}
</style>
